export default class Operations {
	/**
	 * @constructor
	 * @param {App} app - Jet App instance
	 */
	constructor(app) {
		this.app = app;
		this._local = this.app.getService("local");
		this._helpers = this.app.getService("helpers");
		this._back = this.app.getService("backend");
		this._state = this.app.getState();
	}
	/**
	 * Adds task
	 * @param {object} obj - object with task fields
	 * @param {number} index - index within a parent branch, 0 or -1
	 * @param {string, number} parent - parent task id, can be "0" for root
	 * @param {string} mode - (optional) name of the change, "move", "start", "end" or "duration"
	 * @returns {promise} promise that resolves with task object
	 */
	addTask(obj, index, parent, mode) {
		this.updateTaskDuration(obj, mode);
		if (obj.planned_start) this.updatePlannedTaskDuration(obj, mode);

		const addMode = index == 0 ? "first" : "last";
		return this._back
			.addTask(this.cleanData(obj), addMode, parent)
			.then(added => {
				this._local.adjustScale(obj);

				const tasks = this._local.tasks();
				tasks.add({ ...obj, id: added.id, parent }, index, parent);

				return this.completeAdd(parent, added);
			});
	}

	/**
	 * Splits a task via dnd (adds a subtask that will be rendered in the same line)
	 * @param {Object} obj - data of a new subtask
	 * @param {number} index - the index of a new subtask
	 * @param {(string|number)} parent - the ID of the parent task
	 * @param {Object} row - data of the parent task
	 * @returns {Promise<Object>} a promise that resolves with server response (object like {id:"some", sibling:"some"} with task ids)
	 */
	splitTaskWithDnd(obj, index, parent, row) {
		this.updateTaskDuration(obj);
		return this._back
			.updateTask(parent, { ...obj, position: index, parent }, true)
			.then(res => {
				this._local.adjustScale(obj);

				const tasks = this._local.tasks();
				tasks.updateItem(parent, {
					type: "split",
					duration: row.duration || 1,
					progress: row.progress || 0,
				});
				if (
					(res.sibling && res.sibling != 0) ||
					!tasks.find(t => t.parent == parent, true)
				) {
					tasks.add(
						{
							...row,
							type: "task",
							parent,
							id: res.sibling,
							duration: row.duration || 1,
							progress: row.progress || 0,
						},
						0,
						parent
					);
					++index;
				}

				tasks.add(
					{ ...obj, id: res.id, parent, position: index },
					index,
					parent
				);

				return this.completeAdd(parent, res, res.sibling);
			});
	}

	/**
	 * Runs some common logic for add and split operations (see comments inside the method)
	 * @param {(string|number)} parent - the ID of a parent task
	 * @param {Object} added - server response (object like {id:"some", sibling:"some"} with task ids, sib;ing is optional)
	 * @param {id} sibling - (optional) the ID of a first sibling added to split task that was ordinary before splitting
	 * @returns {Promise<Object>} a promise that resolves with server response (object like {id:"some", sibling:"some"} with task ids)
	 */
	completeAdd(parent, added, sibling) {
		if (parent) {
			return this.syncProject(parent).then(() => {
				// critical path update
				if (this._state.criticalPath) this._local.showCriticalPath();

				if (sibling) added.sibling = sibling;
				return added;
			});
		} else return webix.promise.resolve(added);
	}

	/**
	 * Updates task
	 * @param {string, number} id - task id
	 * @param {object} obj - object with task fields
	 * @param {string} mode - (optional) name of the change, "move", "start", "end" or "duration"
	 * @param {boolean} inner - (optional) flag to mark inner logic call
	 * @returns {promise} promise that resolves with task fields
	 */
	updateTask(id, obj, mode, inner) {
		const tasks = this._local.tasks();
		const item = webix.copy(tasks.getItem(id));
		const next = { ...item, ...obj };

		let updateDates = !(
			webix.Date.equal(item.start_date, next.start_date) &&
			webix.Date.equal(item.end_date, next.end_date)
		);
		if (updateDates) {
			this.updateTaskDuration(next, mode);
		}
		const updatePlanned =
			next.planned_start &&
			!(
				webix.Date.equal(item.planned_start, next.planned_start) &&
				webix.Date.equal(item.planned_end, next.planned_end)
			);
		if (updatePlanned) this.updatePlannedTaskDuration(next, mode);

		if (!inner && next.type == "project" && item.type != "project")
			return this.syncProject(id, next);

		return this._back.updateTask(id, this.cleanData(next)).then(() => {
			// scale must be updated before the task
			const { start, end } = this._local.getScales();
			if (
				start > next.start_date ||
				end < next.end_date ||
				(updatePlanned &&
					(start > next.planned_start || end < next.planned_end))
			)
				this._local.adjustScale(next);

			tasks.updateItem(id, next);

			if (next.type != "task" && item.type == "task")
				this.removeAssignments(next);

			//update dependent project or tasks
			if (
				!inner &&
				(updateDates ||
					item.progress != next.progress ||
					item.parent != next.parent)
			) {
				let res;
				if (item.$count && item.type == "project")
					res = this.syncTasks(id, item.start_date, next.start_date).then(
						() => {
							return this.syncProject(item.parent);
						}
					);
				else {
					if (item.parent != next.parent) {
						res = webix.promise.all([
							this.syncProject(next.parent),
							this.syncProject(item.parent),
						]);
					} else {
						res = this.syncProject(next.parent);
					}
				}

				// critical path update
				res.then(() => {
					if (this._state.criticalPath) {
						tasks.updateItem(id, { $critical: false });
						this._local.showCriticalPath();
					}
				});
			}

			return next;
		});
	}

	/**
	 * Normalizes task end_date and duration correspondence
	 * @param {Object} task - a task
	 * @param {string} mode - type of date data operation: "move", "start", "end", "duration", undefined
	 */
	updateTaskDuration(task, mode) {
		if (this.app.config.excludeHolidays) {
			let dir;
			if (mode) {
				mode = mode == "move" ? "start" : mode;
				const old = this._local.tasks().getItem(task.id);
				const field =
					mode + (mode === "start" || mode === "end" ? "_date" : "");
				dir = old[field] > task[field] ? -1 : 1;
				if (mode != "start") mode = "end";
			}
			let scales = webix.copy(this._local.getScales());
			scales.isHoliday = date => {
				return this._local.isHoliday(date, task.id);
			};
			this._helpers.updateTaskDuration(task, scales, mode, dir);
		} else this._helpers.updateTaskDuration(task);
	}

	updatePlannedTaskDuration(task, mode) {
		if (this.app.config.excludeHolidays) {
			let dir;
			if (mode) {
				const old = this._local.tasks().getItem(task.id);
				const field = "planned_" + mode;
				dir = old[field] > task[field] ? -1 : 1;
			}
			let scales = webix.copy(this._local.getScales());
			scales.isHoliday = date => {
				return this._local.isHoliday(date, task.id);
			};
			this._updatePlannedTaskDuration(task, scales, mode, dir);
		} else this._updatePlannedTaskDuration(task);
	}
	_updatePlannedTaskDuration(task, scales, mode, dir) {
		const t = { ...task };
		t.start_date = task.planned_start;
		t.duration = task.planned_duration;
		t.end_date = task.planned_end;
		this._helpers.updateTaskDuration(t, scales, mode, dir);
		task.planned_start = t.start_date;
		task.planned_end = t.end_date;
		task.planned_duration = t.duration;
	}

	/**
	 * Updates related tasks when project start changes
	 * @param {string, number} id - project id
	 * @param {Date} old - old start date
	 * @param {Date} change - current start date
	 */
	syncTasks(id, old, change) {
		let diff = change - old;

		if (this.app.config.excludeHolidays)
			diff = this.getWorkDateDiff(diff, old, id);

		if (diff) {
			const p = [];
			this._local.tasks().data.eachSubItem(id, kid => {
				let newDate = kid.start_date.valueOf() + diff;
				if (this.app.config.excludeHolidays) {
					const delta =
						diff - this.getWorkDateDiff(diff, kid.start_date, kid.id);
					if (delta) newDate = newDate + delta;
				}

				const changedKid = {
					...kid,
					start_date: new Date(newDate),
					end_date: null,
				};

				p.push(this.updateTask(kid.id, changedKid, "move", true));
			});
			return webix.promise.all(p);
		}

		return webix.promise.resolve();
	}
	/**
	 * Extracts working days from date difference
	 * @param {number} diff - difference in days, milliseconds
	 * @param {Date} old - the original date
	 * @param {string} taskId - a task id (optional, resourceCalendars related)
	 * @returns {number} difference in working days in milliseconds
	 */
	getWorkDateDiff(diff, old, taskId) {
		const dir = Math.sign(diff);
		const dayLen = 86400000;
		const ad = dir * Math.floor(diff / dayLen);
		for (let i = 0, date = old; i < ad; ++i) {
			webix.Date.add(date, dir, "day");
			if (this._local.isHoliday(date, taskId)) diff = diff - dir * dayLen;
		}
		return diff;
	}

	/**
	 * Updates related project when task start or end date changes
	 * @param {string, number} id - task id
	 * @param {object} item - task object, if it is converted to project
	 * @returns {Promise}
	 */
	syncProject(id, item) {
		const tasks = this._local.tasks();

		if (!item) {
			item = tasks.getItem(id);
			if (this.app.config.projects && item && item.type != "split") {
				item.type = item.$count ? "project" : "task";
				this.removeAssignments(item);
			} else {
				while (item && item.type != "project") {
					id = tasks.getParentId(id);
					item = tasks.getItem(id);
				}
			}
		}

		if (item && item.$count) {
			item = this.setProjectData(item);
		}
		if (item) {
			return this.updateTask(id, item, "start", true).then(() => {
				this.syncProject(item.parent);
				return item;
			});
		} else return webix.promise.resolve();
	}
	/**
	 * Updates project with task-dependent data taken from tasks collection or an array of child items
	 * @param {object} item - a project object
	 * @param {Object} tasks collection (optional, local.tasks() by default)
	 * @param {array} branch - an array of child items of a project (optional)
	 */
	setProjectData(item, tasks, branch) {
		if (!branch) item = webix.copy(item);
		let min = Infinity,
			max = 0,
			progress = 0,
			duration = 0;

		const handler = kid => {
			min = kid.start_date < min ? kid.start_date : min;
			max = kid.end_date > max ? kid.end_date : max;
			if (kid.duration && !(kid.type == "project" && kid.$count)) {
				progress += kid.duration * kid.progress;
				duration += kid.duration * 1;
			}
		};

		if (branch) branch.forEach(handler);
		else {
			const collection = tasks || this._local.tasks();
			collection.data.eachSubItem(item.id, handler);
		}

		if (!webix.Date.equal(item.start_date, min)) {
			item.start_date = min;
			item.duration = null;
		}
		if (!webix.Date.equal(item.end_date, max)) {
			item.end_date = max;
			item.duration = null;
		}
		item.progress = progress ? Math.round(progress / duration) : progress;
		return item;
	}
	/**
	 * Updates task time after drag-n-drop
	 * @param {string, number} id - task id
	 * @param {string} mode - type of change: "start", "end" or "move"
	 * @param {number} time - number of units by which task was shifted, can be negative
	 * @returns {promise} promise that resolves with change object
	 */
	updateTaskTime(id, mode, time) {
		const tasks = this._local.tasks();
		const task = tasks.getItem(id);

		const obj = {};
		const s = this._local.getScales();

		const unit = s.precise
			? this._helpers.getSmallerUnit(s.minUnit)
			: s.minUnit;
		if (mode === "start" || mode === "move") {
			const offsetDate = s.precise
				? task.start_date
				: this._helpers.getUnitStart(unit, task.start_date);
			obj.start_date = this._helpers.addUnit(unit, offsetDate, time);
			if (mode === "start" && obj.start_date > task.end_date)
				obj.start_date = this._helpers.addUnit(unit, task.end_date, -1);
		} else if (mode === "end") {
			const us = this._helpers.getUnitStart(unit, task.end_date);
			const offsetDate =
				s.precise || webix.Date.equal(us, task.end_date)
					? task.end_date
					: this._helpers.addUnit(unit, us, 1);
			obj.end_date = this._helpers.addUnit(unit, offsetDate, time);

			if (obj.end_date < task.start_date)
				obj.end_date = this._helpers.addUnit(unit, task.start_date, 1);
		}

		if (mode === "move") obj.end_date = null;
		else obj.duration = 0;

		return this.updateTask(id, obj, mode);
	}
	/**
	 * Removes task
	 * @param {string, number} id - task id
	 * @returns {promise} promise that resolves with server response
	 */
	removeTask(id) {
		const tasks = this._local.tasks();
		const links = this._local.links();

		// [FIXME] - multiple redraw calls
		const toRemove = links.find(a => a.source == id || a.target == id);
		toRemove.forEach(a => links.remove(a.id));

		return this._back.removeTask(id).then(res => {
			const parent = tasks.getItem(id).parent;
			tasks.remove(id);

			if (
				this.app.config.split &&
				parent != 0 &&
				tasks.getItem(parent).type === "split" &&
				!tasks.find(k => k.parent == parent, true)
			) {
				return this.updateTask(parent, { type: "task" }).then(() => {
					return this.completeRemoval(res, parent);
				});
			} else return this.completeRemoval(res, parent);
		});
	}
	/**
	 * Synchronizes project and updates critical path after a task is removed
	 * @param {Object} res - server response
	 * @param {(string|number)} parent - the ID of a parent task
	 * @returns {promise} promise that resolves with server response
	 */
	completeRemoval(res, parent) {
		return this.syncProject(parent).then(() => {
			if (this._state.criticalPath) this._local.showCriticalPath();
			return res;
		});
	}
	/**
	 * Adds a link
	 * @param {object} obj - object with link fields
	 * @returns {promise} promise that resolves with link object
	 */
	addLink(obj) {
		if (!this.linkExists(obj)) {
			return this._back.addLink(obj).then(added => {
				obj.id = added.id;
				this._local.links().add(obj);
				return obj;
			});
		}
		return webix.promise.reject();
	}

	/**
	 * Checks if two tasks are already connected by a link of the same type
	 * @param {Object} obj - the data object of a link
	 * @returns {Object, null} - the data object of the link that was found; null if nothing was found
	 */
	linkExists(obj) {
		return this._local.links().find(l => {
			return (
				l.target == obj.target && l.source == obj.source && l.type == obj.type
			);
		}, true);
	}

	/**
	 * Updates a link
	 * @param {string, number} id - link id
	 * @param {object} obj - object with link fields
	 * @returns {promise} promise that resolves with server response
	 */
	updateLink(id, obj) {
		return this._back.updateLink(id, obj).then(res => {
			const links = this._local.links();
			const duplicate = this.linkExists({ ...links.getItem(id), ...obj });
			links.updateItem(id, obj);
			if (duplicate) {
				return this.removeLink(duplicate.id).then(() => res);
			} else return res;
		});
	}
	/**
	 * Removes link
	 * @param {string, number} id - link id
	 * @returns {promise} promise that resolves with server response
	 */
	removeLink(id) {
		return this._back.removeLink(id).then(res => {
			this._local.links().remove(id);
			return res;
		});
	}
	/**
	 * Moves the task to a new position within the task tree
	 * @param {string, number} id - the ID of the task
	 * @param {string, number} parent - the ID of the parent/branch (0 for root)
	 * @param {number} index - the position within the target branch or root (starting from 0)
	 * @returns {Promise} - the promise of data operation
	 */
	moveTask(id, parent, index) {
		parent = parent || 0;

		const tasks = this._local.tasks();
		const branch = tasks.data.branch[parent];
		const oldParent = tasks.getItem(id).parent;
		const oldIndex = tasks.data.getBranchIndex(id);
		if (
			parent == oldParent &&
			(index === oldIndex || (index === -1 && id === branch[branch.length - 1]))
		)
			return webix.promise.reject();

		tasks.move(id, index, null, { parent });

		return this._back
			.reorderTask(id, {
				parent,
				mode: index === 0 ? "first" : index === -1 ? "last" : "after",
				target: tasks.data.branch[parent][index - 1],
			})
			.then(res => {
				if (parent !== oldParent) {
					tasks.updateItem(id, { parent });

					return webix.promise
						.all([this.syncProject(parent), this.syncProject(oldParent)])
						.then(() => {
							if (this._state.criticalPath) this._local.showCriticalPath();
							return res;
						});
				}

				return res;
			});
	}
	/**
	 * Updates a resource assignment
	 * @param {string, number} id -  an id of a resource assignment
	 * @param {object} obj - an object with updated fields
	 * @returns {promise} a promise that is resolved with server response
	 */
	updateAssignment(id, obj) {
		return this._back.updateAssignment(id, obj).then(res => {
			this._local.assignments().updateItem(id, obj);
			return res;
		});
	}
	/**
	 * Assigns a resource assignment to a task
	 * @param {object} obj - an object with "task", "resource" and "value" fields
	 * @returns {promise} a promise that is resolved with an object of a resource assignment
	 */
	addAssignment(obj) {
		return this._back.addAssignment(obj).then(added => {
			obj.id = added.id;
			this._local.assignments().add(obj);
			return obj;
		});
	}
	/**
	 * Removes a resource assignment
	 * @param {string, number} id - an id of a resource assignment
	 * @returns {promise} promise that resolves with server response
	 */
	removeAssignment(id) {
		return this._back.removeAssignment(id).then(res => {
			this._local.assignments().remove(id);
			return res;
		});
	}

	/**
	 * Sorts resources
	 * @param {object} a - a resource to compare
	 * @param {object} b - a resource to compare
	 * @returns {number}
	 */
	sortResources(a, b) {
		if (a.category == b.category) return a.name > b.name ? 1 : -1;
		return a.category > b.category ? 1 : -1;
	}

	/**
	 * Removes all assignments (if any) from a task
	 * @param {object} task - task object
	 * @returns {Promise}
	 */
	removeAssignments(task) {
		if (this.app.config.resources) {
			const collection = this._local.assignments();
			let waitArr = [];
			return collection.waitData.then(() => {
				collection.data.each(item => {
					if (item.task == task.id)
						waitArr.push(this.removeAssignment(item.id));
				});
				if (waitArr.length) return webix.promise.all(waitArr);
				return webix.promise.resolve(false);
			});
		}
		return webix.promise.resolve(false);
	}

	/**
	 * Cleans task data from all $-fields, they are functional and locally used and should not be sent to backend
	 * */
	cleanData(obj) {
		const res = {};
		for (let key in obj) {
			if (key.indexOf("$") !== 0) res[key] = obj[key];
		}
		return res;
	}

	updateAssignedTaskDates(task) {
		const obj = webix.copy(task);
		this.updateTaskDuration(obj);
		const updateDates = !(
			webix.Date.equal(task.start_date, obj.start_date) &&
			webix.Date.equal(task.end_date, obj.end_date)
		);
		if (updateDates) this.updateTask(task.id, obj, null, true);
	}
}
