import BarsView from "../chart/bars";

export default class DiagramView extends BarsView {
	config() {
		this.State = this.getParam("state", true);

		return {
			view: "abslayout",
			css: " webix_gantt_readonly",
			cells: [
				{
					view: "template",
					css: "webix_gantt_holidays",
					borderless: true,
					relative: true,
				},
				{
					view: "tree",
					borderless: true,
					type: this.WorkloadMarkersType(),
					css: `webix_gantt${
						webix.env.touch ? "_touch" : ""
					}_bars webix_gantt_resdiagram_chart`,
					scroll: "xy",
					relative: true,
					filterMode: {
						showSubItems: false,
						level: 0,
					},
				},
			],
		};
	}

	init(view) {
		const [holidays, bars] = view.getChildViews();
		this.Holidays = holidays;
		this.Bars = bars;
		this.LocalState = this.getParam("localState");
		this._unit = "hours";

		const local = (this.Local = this.app.getService("local"));
		const scales = (this.Scales = local.getScales());
		this.RDCollection = this.app.getService("grouping").getRDCollection();
		this.Helpers = this.app.getService("helpers");

		this.on(this.Bars.data, "onSyncApply", () => {
			this.Scales = this.Local.getScales();
			this.RestoreTreeState();
			if (this.Calendars) this.RenderResourceHolidays();
		});

		this.Bars.sync(this.RDCollection, function() {
			this.filter(t => t.$group);
		});

		this.Calendars = this.app.config.resourceCalendars;

		// leave here for app.refresh() calls (inc. from Locale plugin)
		this.DrawGrid(view, scales);

		// the order of DrawGrid-HandleScroll and HandleHolidays is important for the case when
		// main gantt chart is scrolled x and the diagram is empty or has something only in the
		// beginning of x-axis
		this.HandleScroll();
		this.HandleHolidays(scales);

		this.on(this.app, "onScalesUpdate", scales => {
			this.DrawGrid(view, scales);
		});

		this.on(this.app, "rdiagram:branch:toggle", (id, v) => {
			this.ToggleBranch(id, v);
		});

		this.on(this.app, "rdiagram:resize", () => {
			this.holidaysContainer.style.height = this.Bars.$view.scrollHeight + "px";
		});

		this.on(this.app, "rdiagram:sort", (by, dir, as) => {
			if (by) this.Bars.sort(by, dir, as);
			else this.Bars.sort(as, dir);
		});

		if (this.Calendars)
			this.on(this.Local.assignments().data, "onStoreUpdated", () => {
				this.RenderResourceHolidays();
			});
	}

	/**
	 * Opens or closes a branch of the diagram
	 * @param {number} id - the id of a branch
	 * @param {Boolean} v - if true, the branch will be opened; false - closed
	 */
	ToggleBranch(id, v) {
		if (v) this.Bars.open(id);
		else this.Bars.close(id);

		const root = this.Bars.data.getBranch("0");
		const opened = this.Bars.getState().open.filter(i => {
			return root.find(o => o.id == i);
		});

		this._treeOpened = opened.map(id => this.Bars.getIndexById(id));

		this.holidaysContainer.style.height = this.Bars.$view.clientHeight + "px";
		if (this.Calendars) {
			this.resHolidaysContainer.style.height =
				this.Bars.$view.clientHeight + "px";
			this.RenderResourceHolidays();
		}
	}

	/**
	 * Restores opened/closed branches after diagram update and regroup
	 */
	RestoreTreeState() {
		if (this._treeOpened && this._treeOpened.length) {
			const t = this._treeOpened;
			const root = this.Bars.data.getBranch("0");
			root.forEach(b => {
				const ind = root.indexOf(b);
				b.open = !t.indexOf(ind);
				this.Bars.data.callEvent("onStoreUpdated", [b.id, 0, "branch"]);
			});
		} else this.Bars.openAll();
	}

	/**
	 * Handles scrolling
	 */
	HandleScroll() {
		this._scroll_handler = webix.event(this.Bars.$view, "scroll", ev => {
			const bars = ev.target;
			const top = Math.round(bars.scrollTop);
			const left = Math.round(bars.scrollLeft);
			this.getRoot().$view.style.backgroundPosition = `-${left}px -${top}px`;
			this.State.left = left;
			this.LocalState.top = top;
			this.SetHolidaysScroll(bars.scrollHeight, left, top);
		});

		this.on(this.LocalState.$changes, "top", y => {
			this.Bars.scrollTo(this.State.left, y);
		});

		this.on(this.State.$changes, "left", v => {
			this.Bars.scrollTo(v, this.LocalState.top);
		});

		this.on(this.app, "rdiagram:unit:change", u => {
			this._unit = u;
			this.Bars.render();
		});
	}

	/**
	 * Returns all templates for bars
	 * @param {function} _ - the translator function of jet locale plugin, see https://webix.gitbook.io/webix-jet/part-ii-webix-jet-in-details/plugins#applying-the-locale
	 * @returns {Object} - all templates for bars
	 */
	WorkloadMarkersType() {
		return {
			template: task => this.MarkerTemplate(task),
			templateStart: (task, common) => this.MarkerStartTemplate(task, common),
			templateEnd: () => "</div>",
		};
	}

	/**
	 * Defines the html of the data item of the diagram tree
	 * @param {Object} task - data object
	 * @returns {string} with HTML
	 */
	MarkerStartTemplate(task, common) {
		return `<div webix_tm_id="${task.id}" style="height:${this.Scales
			.cellHeight - 8}px; padding: 4px;" class="${common.classname(
			task,
			common
		)} webix_gantt_diagram_line" ${common.aria(task)}>`;
	}

	/**
	 * Defines the looks and contents of a diagram workload marker
	 * @param {Object} task - data object
	 * @returns {string} with HTML
	 */
	MarkerTemplate(task) {
		if (task.$level === 2) {
			const markers = this.GetMarkerData(task);

			const html = [];
			for (const x in markers) {
				html.push(this.MarkerToHTML(markers[x]));
			}

			return html.join("");
		}
		return "";
	}

	CheckResourceHoliday(date, id) {
		if (this.Calendars) {
			const resId = this.Bars.getItem(id).resource_id;
			if (resId) {
				const calendarId = this.Local.resources().getItem(resId).calendar_id;
				if (calendarId) {
					const calendar = this.Local.calendars().getItem(calendarId);
					return this.Helpers.isResourceHoliday(date, calendar);
				}
			}
		}
		return this.app.config.isHoliday(date);
	}
	/**
	 * Calculates work load per unit and prepares the result for display on the diagram
	 * @param {Object} task - the data object of the data collection
	 * @returns {Object} an object with x coordinates as keys and marker data objects and values (hours, count of tasks)
	 */
	GetMarkerData(task) {
		const markers = {};
		for (let a = 0; a < task.duration.length; ++a) {
			let d = task.duration[a];
			const hourParts = task.units[a];
			const delta = task.$x[a] - Math.min(...task.$x);

			const markerWidth = this.Scales.cellHeight > 40 ? 28 : 20;
			const step = this.Scales.cellWidth - markerWidth;
			const halfStep = Math.round(step / 2);
			let x = delta ? -2 : halfStep;
			let date = webix.Date.copy(task.start_date[a]);
			for (let i = 0; i < d; ++i, x += step) {
				if (
					this.app.config.excludeHolidays &&
					this.CheckResourceHoliday(date, task.id)
				) {
					++d;
				} else {
					let hours = 0;
					if (this.Scales.minUnit !== "hour")
						hours =
							(i === d - 1 ? hourParts[1] : i ? hourParts[2] : hourParts[0]) ||
							hourParts[2];
					else {
						hours = hourParts[2];
					}

					const cell = task.$x[a] + i * this.Scales.cellWidth - 2;
					if (!markers[cell]) {
						markers[cell] = { hours, x: cell + halfStep, count: 1 };
					} else {
						markers[cell].hours += hours;
						markers[cell].count += 1;
					}
				}
				webix.Date.add(date, 1, this.Scales.minUnit);
			}
		}

		return markers;
	}

	/**
	 * Returns the html of a marker
	 * @param {Object} marker - an object with data for a marker (hours, count of tasks)
	 */
	MarkerToHTML(marker) {
		let over;
		if (this.Scales.minUnit === "hour") {
			over = Math.round(marker.hours * 24) > this.Helpers.unitLoadNorm.day;
		} else {
			over = marker.hours > this.Helpers.unitLoadNorm[this.Scales.minUnit];
		}
		over = over ? "webix_gantt_overload" : "";

		const number =
			this._unit === "hours"
				? marker.hours >= 1
					? marker.hours
					: " "
				: marker.count;
		return `<div class="webix_gantt_diagram_marker ${over}" style="left:${marker.x}px;">${number}</div>`;
	}

	RenderResourceHolidays(scales) {
		scales = scales || this.Local.getScales();
		let html = "";
		this.Bars.data.each(data => {
			if (data.resource_id) {
				const calendarId = this.Local.resources().getItem(data.resource_id)
					.calendar_id;
				if (calendarId)
					html += this.MarkResourceHolidays(
						scales,
						data.id,
						this.Local.calendars().getItem(calendarId)
					);
			}
		});
		this.resHolidaysContainer.innerHTML = html;
	}
}
