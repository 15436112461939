export default class Local {
	/**
	 * @constructor
	 * @param {App} app - Jet App instance
	 * @param {object} config - object with app settings
	 */
	constructor(app, config) {
		this.app = app;
		this.state = app.getState();

		this.Helpers = this.app.getService("helpers");

		// initial config of scales
		this.resetScales(config);

		this.dateToLocalStr = webix.Date.dateToStr(webix.i18n.parseFormat);
	}

	/**
	 * Creates and paints scales
	 * @param {Date} scaleStart - start date
	 * @param {Date} scaleEnd - end date
	 * @param {boolean} precise - flag that defines whether to round task position and width to a smaller unit
	 * @param {number} width - width of smallest cell unit
	 * @param {number} height - height of scale cell
	 * @param {Array} scales - a set of scales (objects)
	 */
	setScales(scaleStart, scaleEnd, precise, width, height, scales) {
		const oldScale = this._scales;
		if (width) {
			this._scaleBase = { width, height, scales };
			scaleEnd = webix.Date.add(scaleEnd, 1, "day"); //inclusive
		} else {
			width = this._scaleBase.width;
			height = this._scaleBase.height;
			scales = this._scaleBase.scales;
		}

		this._scales = this.app
			.getService("helpers")
			.setScales(scaleStart, scaleEnd, precise, width, height, scales);
		this._taskHeight = this._scales.cellHeight - 12;

		const tasks = this.getVisibleTasksCollection();
		if (tasks && tasks.data.order.length) {
			if (this.state.display === "resources") {
				this.app.getService("grouping").refreshResourceTasks();
			} else {
				this.refreshTasks();
			}
			this.refreshLinks();
		}

		this.app.callEvent("onScalesUpdate", [this._scales, oldScale]);
	}
	/**
	 * returns scales
	 * @returns {object} current scales object
	 */
	getScales() {
		return this._scales;
	}
	/**
	 * sets initial state of Gantt scales
	 * @param {Object} config - scales configuration, by default from app config
	 */
	resetScales(config) {
		config = config || this.app.config;

		const active = webix.skin.$active;
		const scaleStart = config.scaleStart || webix.Date.dayStart(new Date());
		const scaleEnd =
			config.scaleEnd || webix.Date.add(scaleStart, 1, "month", true);

		this.setScales(
			scaleStart,
			scaleEnd,
			config.preciseTimeUnit,
			config.scaleCellWidth || 80,
			active.barHeight - active.borderWidth * 2,
			config.scales || [{ unit: "day", step: 1, format: "%d" }]
		);
	}
	/**
	 * Get tasks height
	 * @returns {number} tasks height
	 */
	getTaskHeight() {
		return this._taskHeight;
	}
	/**
	 * Adjusts scale to edited task
	 * @param {object} task - task object
	 */
	adjustScale(obj) {
		let start = obj.start_date;
		let end = obj.end_date;
		if (this.state.baseline && obj.planned_start && obj.planned_end) {
			if (start > obj.planned_start) start = obj.planned_start;
			if (end < obj.planned_end) end = obj.planned_end;
		}
		const s = this.getScales();

		// add 1 unit space around
		start = this.Helpers.addUnit(s.minUnit, start, -1);
		end = this.Helpers.addUnit(s.minUnit, end, 1);

		if ((s && s.start > start) || s.end < end) {
			this.setScales(
				s.start > start ? start : s.start,
				s.end < end ? end : s.end,
				s.precise
			);
		}
	}
	/**
	 * Fills and returns tasks collection
	 * @returns {object} tasks Tree Collection
	 */
	tasks(force) {
		if (this._tasks && !force) return this._tasks;

		if (!this._tasks) {
			this._tasks = this.createTasks();
		} else {
			this._tasks.clearAll();
		}

		const waitArr = [this.app.getService("backend").tasks()];
		if (this.app.config.resourceCalendars)
			waitArr.push(this.taskCalendarMap(false, force));

		this._tasks.parse(
			webix.promise.all(waitArr).then(data => {
				return this.app.getService("grouping").getTreeData(data[0], 0);
			})
		);
		return this._tasks;
	}
	/**
	 * Creates tasks collection
	 * @returns {object} empty tasks Tree Collection
	 */
	createTasks() {
		const ops = this.app.getService("operations");
		const tasks = new webix.TreeCollection({
			on: {
				"data->onStoreLoad": () => {
					if (!this.app.config.scaleStart) this.updateScaleMinMax(tasks);
					tasks.data.each(d => {
						if (this.app.config.projects && d.$count) d.type = "project";
					});
				},
				"data->onStoreUpdated": (id, obj, mode) => {
					id = mode == "update" ? id : null;
					this.refreshTasks(id);
					this.refreshLinks();

					if (!mode && this.state.criticalPath) {
						if (this.app.config.links)
							this._links.waitData.then(() => this.showCriticalPath());
						else this.showCriticalPath();
					}
				},
			},
			scheme: {
				$change: obj => {
					obj.start_date = webix.i18n.parseFormatDate(obj.start_date);
					obj.end_date = webix.i18n.parseFormatDate(obj.end_date);
					obj.type = obj.type || "task";
					if (!this.app.config.split && obj.type === "split") obj.type = "task";

					ops.updateTaskDuration(obj);

					if (obj.planned_start) {
						obj.planned_start = webix.i18n.parseFormatDate(obj.planned_start);
						obj.planned_end = webix.i18n.parseFormatDate(obj.planned_end);
						ops.updatePlannedTaskDuration(obj);
					}

					if (obj.type === "split") {
						obj.open = obj.opened = 0;
						obj.$css = "webix_gantt_split_task";
					} else {
						delete obj.$css;
						obj.open = obj.opened ? obj.opened * 1 : 0;
					}
				},
				$sort: {
					by: "position",
					dir: "asc",
					as: "int",
				},
				$serialize: data => this.taskOut(data),
				$export: data => this.taskOut(data),
			},
		});

		return tasks;
	}
	/**
	 * Re-paints all tasks in chart or, if theID is provided, a specific task
	 * @param {string|number} updID - (optional) the ID of the task
	 * @param {number} i - the index of the task
	 */
	refreshTasks(updID, i) {
		// console.log("[x] render tasks");
		if (!updID) {
			this._tasks.data.order.forEach((id, i) => {
				this.refreshTasks(id, i);
			});
		} else {
			const t = this._tasks.getItem(updID);
			i = !webix.isUndefined(i) ? i : this._tasks.getIndexById(updID);
			// type:"split" results in split kids missing in datastore.order
			if (i < 0) i = this._tasks.getIndexById(t.parent);

			if (t.type === "split") {
				t.$data = this._tasks
					.find(k => k.parent == t.id)
					.sort(
						(a, b) => b.start_date - a.start_date || b.end_date - a.end_date
					);
			}
			this.Helpers.updateTask(t, i);

			if (this.state.baseline && t.planned_start) this.refreshBaseline(t, i);
		}
	}

	/**
	 * Arranges task baseline
	 * @param task {object} task object
	 * @param i {number} task index

	 */
	refreshBaseline(task, i) {
		const t = { ...task };
		t.start_date = task.planned_start;
		t.duration = task.planned_duration;
		t.end_date = task.planned_end;
		this.Helpers.updateTask(t, i, this._scales, this._taskHeight);
		task.$x0 = t.$x;
		task.$w0 = t.$w;
	}

	/**
	 * Fills and returns links collection
	 * @returns {object} links Data Collection
	 */
	links(force) {
		if (this._links && !force) return this._links;

		if (!this._links) {
			this._links = this.createLinks();
		} else {
			this._links.clearAll();
		}

		if (this.app.config.links)
			this._links.parse(this.app.getService("backend").links());
		return this._links;
	}
	/**
	 * Creates and returns links collection
	 * @returns {object} empty links Data Collection
	 */
	createLinks() {
		return new webix.DataCollection({
			on: {
				"data->onStoreUpdated": (id, v, mode) => {
					// do not refresh for initial data parsing
					if (mode) this.refreshLinks(id);
				},
			},
			scheme: {
				$init: obj => {
					obj.type = obj.type * 1;
				},
			},
		});
	}
	/**
	 * Re-paints all links in chart
	 * @param {(string|number)} id - (optional) the ID of a link
	 */
	refreshLinks(id) {
		const tasks = this.getVisibleTasksCollection();
		if (this._links && tasks) {
			this._links.data.each(l => {
				const s = tasks.getItem(l.source);
				const e = tasks.getItem(l.target);
				if (!s || !e || !this._isTaskVisible(s) || !this._isTaskVisible(e))
					l.$p = "";
				else this.Helpers.updateLink(l, s, e);
			});

			// refresh the critical path if a link was added, updated or removed
			if (this.state.criticalPath && id) {
				this.showCriticalPath();
			}
		}
	}
	/**
	 * Returns all links of a particular type for the specified task
	 * @param {string, number} id - task id
	 * @param {type} type - link type, "source" or "target"
	 * @returns {array} links array
	 */
	getLinks(id, type) {
		const found = this._links.find(a => a[type] == id);
		const res = [];

		for (let i = 0; i < found.length; i++) {
			const a = found[i];
			const t = this._tasks.getItem(a[type == "source" ? "target" : "source"]);
			if (t)
				res.push({
					id: a.id,
					type: a.type.toString(),
					text: t.text,
					ttype: type,
				});
		}
		return res;
	}

	/**
	 * Prepares task data object for serializing and export
	 * @param {Object} data - incoming task data object
	 * @returns {Object} - processed task data object
	 */
	taskOut(data) {
		data = webix.copy(data);

		const format = this.dateToLocalStr;
		if (data.start_date) data.start_date = format(data.start_date);
		if (data.end_date) data.end_date = format(data.end_date);

		// clean $-fields
		for (let k in data) {
			if (k.indexOf("$") === 0) delete data[k];
		}

		return data;
	}

	/**
	 * Checks whether the specified task is visible (all parents open)
	 * @param {object} x - task object
	 * @param {boolean} tcollection - if true, the method will return the main collection regardless of the current display mode
	 * @returns {boolean} task visibility
	 */
	_isTaskVisible(x, tcollection) {
		// display: "resources" case to hide projects
		const tasks = this.getVisibleTasksCollection(tcollection);

		const taskParent = x.$parent;
		const taskSplit = x.type === "split" && !x.open;

		if (taskSplit && !taskParent) return false;
		while (x.$parent) {
			x = tasks.getItem(x.$parent);
			if (
				!((taskParent == x.id && x.type == "split") || x.open) ||
				(x.open && taskSplit)
			) {
				return false;
			}
		}

		return true;
	}

	/**
	 * Creates, fills and returns resource categories collection
	 * @returns {object} categories Data Collection
	 */
	categories(force) {
		if (this._categories && !force) return this._categories;
		if (!this._categories) {
			this._categories = new webix.DataCollection({});
		} else {
			this._categories.clearAll();
		}

		this._categories.parse(this.app.getService("backend").categories());
		return this._categories;
	}

	/**
	 * Creates, fills and returns resource collection
	 * @returns {object} resources Data Collection
	 */
	resources(force) {
		if (this._resources && !force) return this._resources;

		if (!this._resources) {
			this._resources = new webix.DataCollection({});
		} else {
			this._resources.clearAll();
		}

		let waitArr = [
			this.app.getService("backend").resources(),
			this.categories(force).waitData,
		];
		if (this.app.config.resourceCalendars)
			waitArr.push(this.calendars(force).waitData);

		this._resources.parse(
			webix.promise.all(waitArr).then(arr => {
				let rData = arr[0];
				rData.forEach(resource => {
					if (resource["category_id"]) {
						const ctg = this.categories().getItem(resource["category_id"]);
						resource.category = ctg.name;
						if (!resource.unit && ctg.unit) resource.unit = ctg.unit;
					}
				});
				rData.sort(this.app.getService("operations").sortResources);
				return rData;
			})
		);
		return this._resources;
	}

	/**
	 * Creates, fills and returns resource-task relation collection
	 * @returns {object} assignments Data Collection
	 */
	assignments(force) {
		if (this._assignments && !force) return this._assignments;

		if (!this._assignments) {
			this._assignments = new webix.DataCollection({});
		} else {
			this._assignments.clearAll();
		}

		this._assignments.parse(this.app.getService("backend").assignments());
		return this._assignments;
	}

	/**
	 * Returns all resource assignments for the specified task
	 * @param {string, number} id - a task id
	 * @returns {array} an array of assignments extended with resource properties
	 */
	getAssignments(id) {
		return webix.promise
			.all([this.resources().waitData, this.assignments().waitData])
			.then(() => {
				const resources = this.resources();
				const assigned = this.assignments().data.find(a => a.task == id);
				return assigned.map(a => {
					let aCopy = Object.assign({}, a);
					let resource = Object.assign({}, resources.getItem(a.resource));
					delete resource.id;
					return Object.assign(aCopy, resource);
				});
			});
	}

	/**
	 * Fills and returns calendars collection for resources
	 * @returns {object} calendars Data Collection
	 */
	calendars(force) {
		if (this._calendars && !force) return this._calendars;

		if (!this._calendars) {
			this._calendars = this.createCalendars();
		} else {
			this._calendars.clearAll();
		}

		this._calendars.parse(this.app.getService("backend").calendars());
		return this._calendars;
	}
	/**
	 * Creates calendars collection for resources
	 * @returns {object} calendars Data Collection
	 */
	createCalendars() {
		return new webix.DataCollection({
			scheme: {
				$change: obj => {
					if (typeof obj.weekDays == "string")
						obj.weekDays = obj.weekDays.split(",").map(a => 1 * a);
					if (obj.holidays) {
						if (typeof obj.holidays == "string")
							obj.holidays = obj.holidays.split(",");
						obj.holidays = obj.holidays.map(d =>
							webix.Date.datePart(new Date(d))
						);
					}
				},
			},
		});
	}

	/**
	 * Marks all tasks as critical or not
	 * @param {Boolean} clean - pass true if you want to remove existing critical path markers
	 */
	showCriticalPath(clean) {
		if (clean) {
			this._tasks.data.order.forEach(id => {
				const task = this._tasks.getItem(id);
				delete task.$critical;
			});
		} else {
			const all = this._tasks.data.getRange().sort((a, b) => {
				return (
					b.end_date - a.end_date ||
					(a.parent == b.id ? 1 : b.parent == a.id ? -1 : 0) ||
					b.start_date - a.start_date
				);
			});

			let latestDate = webix.Date.copy(all[0].end_date);
			all.forEach(task => {
				task.$critical = this.isTaskCritical(task, latestDate);
			});
		}
		this._tasks.data.callEvent("onStoreUpdated", [null, null, "paint"]);
	}
	/**
	 * Decides if a task is a part of the critical path
	 * @param {Object} task - task data
	 * @param {Date} latestDate - the date by which all tasks end
	 * @returns {Boolean}
	 */
	isTaskCritical(task, latestDate) {
		if (latestDate && webix.Date.equal(latestDate, task.end_date)) {
			return true;
		} else {
			const links = this._links.find(l => l.source == task.id && l.type == 0);
			for (let i = 0; i < links.length; ++i) {
				// a task is critical if its end is linked to the start of a critical task
				// and there is no slack time between them
				const dependent = this._tasks.getItem(links[i].target);
				if (
					dependent.$critical &&
					this.isNoSlack(dependent.start_date, task.end_date, task.id)
				)
					return true;
			}

			if (task.parent != 0) {
				const parent = this._tasks.getItem(task.parent);
				return parent.$critical && task.end_date >= parent.end_date;
			}

			return false;
		}
	}

	/**
	 * Checks if there is slack between 2 linked tasks
	 * @param {Date} nextStart - the start date of the next linked task
	 * @param {Date} currentEnd - the end date of the currently checked task
	 * @param {String} taskId - the id of the currently checked task (optional, resourceCalendar related)
	 * @returns {Boolean} the result of the check; true if there is no slack
	 */
	isNoSlack(nextStart, currentEnd, taskId) {
		if (this.app.config.excludeHolidays) {
			if (currentEnd >= nextStart) return true;

			for (let date = webix.Date.copy(currentEnd); date < nextStart; ) {
				if (!this.isHoliday(date, taskId)) return false;
				webix.Date.add(date, 1, "day");
			}
			return true;
		}
		return currentEnd >= nextStart;
	}

	/**
	 * Decides if a link is a part of the critical path
	 * @param {Object} link - the data object of a link
	 * @returns {Boolean} the result of the check; true if the link connects critical tasks
	 */
	isLinkCritical(link) {
		let critical = false;
		if (link.type == 0) {
			const tasks = this.tasks();
			const s = tasks.getItem(link.source);
			const t = tasks.getItem(link.target);
			critical = s && t && s.$critical && t.$critical;
		}
		return critical;
	}

	/**
	 * Gets visible tasks collection
	 * @param {boolean} tasks - if true, the method will return the main collection regardless of the current display mode
	 * @returns {object} webix.TreeCollection
	 */
	getVisibleTasksCollection(tasks) {
		if (!tasks && this.state.display == "resources") {
			const g = this.app.getService("grouping");
			return g ? g.getResourceTree() : null;
		}
		return this._tasks;
	}

	taskCalendarMap(now, force) {
		if (now) return this._calendarMap || null;

		if (!this._calendarMap) {
			this.assignments().data.attachEvent("onStoreUpdated", () => {
				this.refreshCalendarMap();
			});
			this.resources().data.attachEvent("onStoreUpdated", () => {
				this.refreshCalendarMap();
			});
			this.calendars().data.attachEvent("onStoreUpdated", () => {
				this.refreshCalendarMap();
			});
		}

		const waitArr = [
			this.assignments(force).waitData,
			this.resources(force).waitData,
		];

		// forced from resources
		if (!force) {
			waitArr.push(this.calendars().waitData);
		}

		return webix.promise.all(waitArr).then(() => {
			if (!this._calendarMap) this.refreshCalendarMap();
			return this._calendarMap;
		});
	}
	refreshCalendarMap() {
		const resources = this.resources();
		const calendars = this.calendars();
		let data = {};
		this.assignments().data.each(obj => {
			const resource = resources.getItem(obj.resource);
			if (resource) {
				const id = resource.calendar_id;
				const cal = id ? calendars.getItem(id) : null;
				if (cal && !data[obj.task]) data[obj.task] = cal;
			}
		});
		return (this._calendarMap = data);
	}

	getTaskCalendar(taskId) {
		const map = this.taskCalendarMap(true);
		return map ? map[taskId] : null;
	}
	isHoliday(date, taskId) {
		if (taskId && this._calendarMap) {
			const calendar = this._calendarMap[taskId];
			if (calendar) return this.Helpers.isResourceHoliday(date, calendar);
		}
		return this.app.config.isHoliday(date);
	}

	/**
	 * Clears all existing data collections; resets aggregated data, state, and scales
	 */
	clearAll() {
		const grouping = this.app.getService("grouping");
		const collections = [
			"tasks",
			"links",
			"resources",
			"categories",
			"assignments",
			"calendars",
		];

		this.state.$batch({
			edit: null,
			selected: null,
			sort: null,
			top: 0,
			left: 0,
		});

		if (this.app.config.resourcesDiagram) {
			const rdCollection = grouping.getRDCollection();
			rdCollection.clearAll();
		}

		if (this.app.config.resources) {
			const rCollection = grouping.getResourceTree();
			rCollection.clearAll();
		}

		collections.forEach(name => {
			const key = "_" + name;
			if (this[key]) this[key].clearAll();
		});

		this.resetScales();
	}

	/**
	 * Completely reloads data in the component; repaints application with new data
	 */
	reload() {
		this.clearAll();

		const grouping = this.app.getService("grouping");
		const collections = ["tasks", "links"];
		const waitArr = [];

		if (this.app.config.resources && !this.app.config.resourceCalendars) {
			collections.push("resources", "assignments");
		}

		collections.forEach(name => {
			const collection = this[name](true);
			waitArr.push(collection.waitData);
		});

		if (this.app.config.resourcesDiagram) {
			grouping.getRDCollection(true);
		}

		webix.promise.all(waitArr).then(() => {
			this.app.refresh();
		});
	}
	updateScaleMinMax(tasks) {
		let min = Infinity;
		let max = -Infinity;
		tasks.data.each(d => {
			if (d.start_date < min) min = d.start_date;
			if (d.end_date > max) max = d.end_date;
			if (this.state.baseline && d.planned_start && d.planned_end) {
				if (d.planned_start < min) min = d.planned_start;
				if (d.planned_end > max) max = d.planned_end;
			}
		});
		if (typeof min === "object") {
			const s = this.getScales();
			this.setScales(
				this.Helpers.addUnit(s.minUnit, min, -1),
				this.Helpers.addUnit(s.minUnit, max, 1),
				s.precise
			);
		}
	}
}
